<template>
  <b-row>
    <b-col>
      <b-card class="p-1 p-md-3">
        <b-card-title>
          {{ $t('invest-type-select-text') }}
        </b-card-title>
        <b-row>
          <div class="d-flex flex-column flex-md-row  w-100 justify-content-center align-items-center">
            <b-button
              class="d-flex justify-content-center align-items-center btn-style"
              variant="success"
              :to="{name:'investModule-deposit',params:{type:'IRR',lang:'fa'}}"
            >
              <div>
                <h4 class="text-white">
                  {{ $t('rial-plans') }}
                </h4>
              </div>
            </b-button>
            <b-button
              class="d-flex justify-content-center align-items-center btn-style"
              variant="info"
              :to="{name:'investModule-deposit',params:{type:'USD',lang:'fa'}}"
            >
              <h4 class="text-white">
                {{ $t('dollar-plans') }}
              </h4>
            </b-button>
          </div>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BButton, BRow, BCol, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {

    BCard,
    BButton,
    BRow,
    BCol,
    BCardTitle,
  },
  data() {
    return {

    }
  },
  computed: {
    ENABLED_CRYPTO_BANK_DEPOSIT() {
      return process.env.VUE_APP_IS_ENABLED_CRYPTO_BANK_DEPOSIT === 'true'
    },
  },
}
</script>
<style>
.btn-style {
  margin: 5px;
  width: 100%;
  height: 100px;
}
</style>
